import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

let crypto: Crypto;

if (typeof window === 'undefined') {
  // Server-side
  import('node:crypto').then(nodeCrypto => {
    crypto = {
      getRandomValues: (array: Uint8Array) => {
        const bytes = nodeCrypto.randomBytes(array.length);
        array.set(bytes);
        return array;
      },
      subtle: nodeCrypto.webcrypto.subtle
    } as Crypto;
  });
} else {
  // Client-side
  crypto = window.crypto;
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ??
    request.headers.get("host") ??
    new URL(request.url).host;
  const protocol = host.includes("localhost") ? "http" : "https";
  return `${protocol}://${host}`;
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
  condition: unknown,
  message: string | (() => string)
): asserts condition {
  if (!condition) {
    throw new Error(typeof message === "function" ? message() : message);
  }
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
  condition: unknown,
  message: string | (() => string),
  responseInit?: ResponseInit
): asserts condition {
  if (!condition) {
    throw new Response(typeof message === "function" ? message() : message, {
      status: 400,
      ...responseInit,
    });
  }
}

export async function downloadFile(url: string, retries = 0) {
  const MAX_RETRIES = 3;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image with status ${response.status}`);
    }
    const contentType = response.headers.get("content-type") ?? "image/jpg";
    const blob = Buffer.from(await response.arrayBuffer());
    return { contentType, blob };
  } catch (e) {
    if (retries > MAX_RETRIES) throw e;
    return downloadFile(url, retries + 1);
  }
}

export function signMessage(message: string | Buffer, sharedKey: string) {
  return crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(sharedKey),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  ).then(key => 
    crypto.subtle.sign(
      "HMAC",
      key,
      typeof message === 'string' ? new TextEncoder().encode(message) : message
    )
  ).then(signature => 
    btoa(String.fromCharCode(...new Uint8Array(signature)))
  );
}

/**
 * Checks if a number is 9 digits starting with '0', strips the '0', adds 256, and returns the modified number.
 * Otherwise, returns the original number.
 * @param phoneNumber The phone number to check and modify.
 */
export function normalizeUgandanNumber(phoneNumber: string): string {
  // Check for 10 digits starting with '0' using a regular expression
  if (/^0\d{9}$/.test(phoneNumber)) {
    // Strip the '0', parse to a number, add 256, and convert back to a string
    return `256${phoneNumber.slice(1)}`;
  }
  // If pattern doesn't match, return the original number
  return phoneNumber;
}

export function generateRandomString(length: number): string {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
}
